<template>
    <div class="statusPage row align-items-center">
      <div class="col col-md-5">

          {{ statusPage.title || 'Status page without name' }}

      </div>
      <div class="col col-md-6">
        <div class="public-link">
          <a :href="publicLink"
             target="_blank">
            {{ publicLink() }}
          </a>
        </div>
      </div>
      <div class="col col-md-1 d-flex justify-content-end">
        <base-button type="button"
                     :loading="loading"
                     @click.prevent="onDelete">Unsubscribe</base-button>
      </div>
    </div>
</template>

<script>
import statusPagesApi from '@/api/statusPagesApi'

export default {
  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    async onDelete () {
      this.loading = true
      try {
        await statusPagesApi.unsubscribe(this.statusPage.id, window.sessionStorage.getItem('hash'))
        this.$emit('delete')
      } catch (e) {
        this.$emit('reset')
      }
      this.loading = false
    },

    publicLink () {
      const a = document.createElement('a')
      a.href = this.$router.resolve(this.publicRoute).href

      return a.href
    },

    publicRoute () {
      return {
        name: 'status',
        params: {
          slug: this.statusPage.slug
        }
      }
    }
  }
}
</script>

<style scoped>
  .statusPage {
    width: 100%;
    height: 70px;
    border: 1px solid rgb(212, 218, 231);
    border-radius: 4px;
    background-color: #eee!important;
    margin-bottom: 16px;
    transition: box-shadow 200ms ease 0s, border-color 200ms ease 0s;
  }
</style>
