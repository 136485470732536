<template>
  <div>
      <status-page-subscriptions />
  </div>
</template>

<script>
import StatusPageSubscriptions from '@/components/StatusPage/StatusPageSubscriptions'

export default {
  components: {
    StatusPageSubscriptions
  }
}
</script>
