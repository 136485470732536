<template>
  <div>
    <base-modal name="statusPageGetHashEmail"
                width="40%"
                height="auto">
      <template #header>
        Confirm your email address
      </template>

      <form @submit.prevent="runCaptcha">
        <div class="d-flex justify-content-center mt-3 mx-5">
          <base-textbox placeholder="your@email.com"
                        v-model="email" class="mx-2 w-100"/>
          <base-button type="submit" v-bind:disabled="!isDisabled" :loading="loading">Submit</base-button>
        </div>
        <base-alert v-if="info"
                    type="info">A message has been sent to your email address. You can click on the link provided to access your subscriptions</base-alert>

        <base-alert v-if="error"
                    type="error">Something went wrong.</base-alert>
        <vue-recaptcha ref="recaptcha"
                       size="invisible"
                       :sitekey="recaptchaKey"
                       @verify="sendUrlEmail"
                       @expired="onCaptchaExpired" />
      </form>

      <template #footer>

      </template>
    </base-modal>

    <div class="container mt-5">
      <div class="d-flex justify-content-center">
        <h3>Status pages subscriptions</h3>
      </div>

      <status-page-subscription-item v-for="statusPage in statusPages"
                        :key="statusPage.id"
                        :status-page="statusPage"
                        @delete="getSubscriptionsByHash()" @reset="resetData()"/>

    </div>

    <div class="d-flex justify-content-center" v-if="statusPages.length === 0">
      <h3>No pages for you yet</h3>
    </div>

  </div>
</template>

<script>
import statusPagesApi from '@/api/statusPagesApi'
import StatusPageSubscriptionItem from './StatusPageSubscriptionItem'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'statusPageSubscriptions',

  components: {
    StatusPageSubscriptionItem,
    VueRecaptcha
  },

  data () {
    return {
      email: '',
      loading: false,
      info: false,
      error: null,
      hash: null,
      statusPages: [],
      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    }
  },

  mounted () {
    this.getUrlHashAndCheckValidity()
  },

  computed: {
    isDisabled () {
      return this.email.length > 0
    }
  },

  methods: {
    async getUrlHashAndCheckValidity () {
      if (location.hash) {
        const hash = location.hash.substr(1)
        try {
          await statusPagesApi.checkHashValidity(hash)
          // save hash to sessionStorage
          this.saveHashToSessionStorage(hash)
          // get subscriptions using hash
          await this.getSubscriptionsByHash()
        } catch (e) {
          this.$modal.show('statusPageGetHashEmail')
        }
      } else {
        this.$modal.show('statusPageGetHashEmail')
      }
    },

    saveHashToSessionStorage (hash) {
      window.sessionStorage.setItem('hash', hash)
    },

    runCaptcha () {
      this.saving = true
      this.$refs.recaptcha.execute()
    },

    async sendUrlEmail (recaptcha_token) {
      this.loading = true
      this.error = null
      this.info = false
      try {
        const data = { email: this.email, recaptcha_token }
        await statusPagesApi.sendUrlEmail(data)
        this.info = true
        this.email = ''
      } catch (error) {
        this.error = error.message
      }
      this.loading = false
    },

    async getSubscriptionsByHash () {
      if (sessionStorage.getItem('hash')) {
        try {
          const response = await statusPagesApi.getSubscriptionsByHash(sessionStorage.getItem('hash'))
          this.statusPages = response.data
        } catch (error) {
          console.error(error)
        }
      }
    },

    resetData () {
      this.email = ''
      this.loading = false
      this.info = false
      this.error = null
      this.hash = null
      this.statusPages = []
      this.getUrlHashAndCheckValidity()
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>
